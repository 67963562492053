import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Clean 4×6\\@70% 1RM`}</p>
    <p>{`Single Leg Shoulder Bridge 4×6/leg`}</p>
    <p>{`then,`}</p>
    <p>{`15-12-9-6-3 reps each of:`}</p>
    <p>{`Power Clean (135/95)`}</p>
    <p>{`Front Squat`}</p>
    <p>{`Burpee Over Bar`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      